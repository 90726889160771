<template>
  <div>
    <el-row :gutter="20" class="examQuestionsManageTop">
      <el-col :span="8" v-for="(item, i) in subjectList" :key="item.name">
        <template v-if="item.subjectType <= 3 && item.subjectType >= 1">
          <div
            class="subjectBox"
            @click="clickBtn(i, item.subjectType)"
            :class="checkedNum == i + 1 ? 'active' : ''"
          >
            {{ item.subjectType | subjectFilter('考题') }}
            <span>
              {{ item.subjectTotal }}
              <i>道</i>
            </span>
            <div class="iconBig">
              <img
                :src="
                  require(`../../assets/examQuestions/${item.subjectType}.png`)
                "
              />
              <!-- <img :src="require(`../../assets/examQuestions/${item.subjectType}.png`)" alt /> -->
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
    <!-- 条件 -->
    <el-row>
      <div style="display: inline-block">
        <el-upload
          style="display: inline-block; margin: 0 10px"
          :limit="1"
          action="https://jsonplaceholder.typicode.com/posts/"
          :http-request="uploadBtn"
          :show-file-list="false"
        >
          <el-button
            v-throttle
            size="medium"
            type="primary"
            icon="el-icon-upload2"
            >导入</el-button
          >
        </el-upload>
        <el-upload
          style="display: inline-block; margin: 0 10px"
          :limit="1"
          action="https://jsonplaceholder.typicode.com/posts/"
          :http-request="importConfigBtn"
          :show-file-list="false"
          ref="upload"
        >
          <el-button
            v-throttle
            size="medium"
            type="primary"
            icon="el-icon-upload2"
            >导入配置文件</el-button
          >
        </el-upload>
        <el-button size="medium" type="primary" @click="flushedAIBtn"
          >重新加载</el-button
        >
        <el-button size="medium" @click="deleteBtn(true)" type="danger" plain
          >批量删除</el-button
        >
      </div>
      <el-form
        :inline="true"
        :model="params"
        style="float: right; padding-right: 20px"
      >
        <!--  -->
        <el-form-item label="实验名称/实验ID" class="formItemBoxStyle">
          <el-input v-model="params.query"></el-input>
        </el-form-item>
        <el-button
          type="primary"
          size="medium"
          @click="
            () => {
              this.tableDataPagination.pageIndex = 1
              this.changeConditionForm()
            }
          "
          >查询</el-button
        >
      </el-form>
    </el-row>
    <!-- 表单 -->
    <el-table
      v-loading="loading"
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <el-table-column
        type="index"
        align="center"
        width="55"
        label="序号"
      ></el-table-column>
      <el-table-column align="center" width="70" label="科目">
        <template slot-scope="scope">
          {{ scope.row.subjectType | subjectFilter }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="aiOperationTestName"
        label="实验名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="aiOperationTestCode"
        label="实验ID"
      ></el-table-column>
      <el-table-column align="center" label="关联考题">
        <template slot-scope="scope">
          <el-link
            type="primary"
            @click="
              relevancyBtn(scope.row, scope.row.examQuestionOperationTestId)
            "
            >{{
              !!scope.row.isExam
                ? scope.row.examQuestionOperationTestName
                : '未关联'
            }}</el-link
          >
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="aiOperationTestVersion"
        label="采集器版本号"
        width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="aiOperationTestVersion"
        label="判定器版本号"
        width="120"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="aiMarking"
        label="开启AI阅卷"
        width="100"
      >
        <template slot-scope="scope">
          <!-- <span>{{ scope.row.aiMarking ? '是' : '否' }}</span> -->
          <el-switch
            v-model="scope.row.aiMarking"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
            @change="switchAiMarking(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="440">
        <template slot-scope="scope">
          <el-button @click="editBtn(scope.row)" size="mini" type="success"
            >编辑</el-button
          >
          <el-button @click="scoreBtn(scope.row)" size="mini" type="primary"
            >评分配置</el-button
          >
          <el-button @click="editStepsBtn(scope.row)" size="mini" type="primary"
            >步骤编辑</el-button
          >
          <el-button
            @click="electronPaperEditBtn(scope.row)"
            size="mini"
            type="primary"
            >电子试卷编辑</el-button
          >
          <el-button
            @click="deleteBtn(false, scope.row)"
            size="mini"
            type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
    <!-- 关联弹框 -->
    <el-dialog
      title="关联考题"
      :visible.sync="dialogVisibleRelevancy"
      width="1080px"
    >
      关联阅卷考题&nbsp;&nbsp;
      <el-select
        v-model="relevancyExamQuestionOperationTestId"
        placeholder="请选择"
        @change="relevancyChangSelect"
      >
        <el-option label="不关联" value></el-option>
        <el-option
          v-for="item in dialogVisibleRelevancyData"
          :key="item.examQuestionOperationTestId"
          :label="item.operationTestName"
          :value="item.examQuestionOperationTestId"
        >
        </el-option>
      </el-select>
      <div
        style="display: flex; overflow-y: auto; justify-content: space-between"
      >
        <div style="width: 510px">
          <div style="font-size: 16px">预览</div>
          <el-table
            header-align="center"
            border
            :header-cell-style="{ background: '#DFE6EC' }"
            :data="itemDTOList"
            height="500px"
          >
            <el-table-column
              type="index"
              align="center"
              width="55"
              label="序号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operationItemName"
              label="评分项"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="score"
              label="分数"
              width="55"
            ></el-table-column>
          </el-table>
        </div>
        <div style="width: 510px">
          <div style="font-size: 16px">
            当前AI考题（
            {{ dialogVisibleEditData.examQuestionOperationTestName }}）
          </div>
          <el-table
            header-align="center"
            border
            :header-cell-style="{ background: '#DFE6EC' }"
            :data="stepsList"
            height="500px"
          >
            <el-table-column
              type="index"
              align="center"
              width="55"
              label="序号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="aiOperationItemContent"
              label="评分项"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="aiScoreFull"
              label="分数"
              width="55"
            ></el-table-column>
            <el-table-column
              width="70"
              align="center"
              prop="aiSupport"
              label="AI支持"
            >
              <template slot-scope="scope">
                <i
                  style="color: #01a850"
                  v-if="scope.row.aiSupport"
                  class="el-icon-success"
                ></i>
                <i style="color: #fe4640" v-else class="el-icon-error"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleRelevancy = false">取 消</el-button>
        <el-button type="primary" @click="saveRelevancyBtn">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="1000px">
      <div style="display: flex; height: 600px; overflow-y: auto">
        <el-form
          :model="dialogVisibleEditData"
          label-width="90px"
          style="width: 480px"
        >
          <el-form-item label="实验名称">
            <el-input
              disabled
              v-model="dialogVisibleEditData.aiOperationTestName"
            ></el-input>
          </el-form-item>
          <el-form-item label="实验ID">
            <el-input
              v-model="dialogVisibleEditData.aiOperationTestCode"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="考题内容">
            <el-input
              type="textarea"
              v-model="dialogVisibleEditData.aiOperationTestContent"
            ></el-input>
          </el-form-item>
          <el-form-item label="学科">
            <el-select
              v-model="dialogVisibleEditData.subjectType"
              placeholder="学科"
              disabled="true"
              style="width: 130px; margin-right: 30px"
            >
              <el-option label="物理" :value="1"></el-option>
              <el-option label="生物" :value="2"></el-option>
              <el-option label="化学" :value="3"></el-option>
            </el-select>
            <el-form-item
              v-show="microscopeShow"
              label=" 开启显微镜"
              label-width="90px"
              style="display: inline-block"
            >
              <el-switch
                v-model="dialogVisibleEditData.microscope"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="0"
                disabled="true"
              >
              </el-switch>
            </el-form-item>
          </el-form-item>
          <el-form-item label="考题头像">
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="uploadAvatarUrlImg"
              ref="uploadAvatarUrlImg"
              :show-file-list="false"
              :multiple="false"
            >
              <img
                v-if="dialogVisibleEditData.info?.avatarUrl"
                :src="this.$imageAddress(dialogVisibleEditData.info?.avatarUrl)"
                class="uploadImg"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="电子试卷">
            <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="uploadPaperImgUrlImg"
              ref="uploadPaperImgUrlImg"
              :show-file-list="false"
              :multiple="false"
            >
              <img
                v-if="dialogVisibleEditData.info?.paperImgUrl"
                :src="
                  this.$imageAddress(dialogVisibleEditData.info?.paperImgUrl)
                "
                class="uploadImgPaper"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-form
          :model="dialogVisibleEditData"
          label-width="90px"
          style="width: 480px"
        >
          <el-form-item label="实验器材">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="uploadEquipmentImgJsonUrlImg"
              ref="uploadEquipmentImgJsonUrlImg"
              :show-file-list="false"
              :multiple="true"
            >
              <el-button size="mini" type="primary">上传实验器材</el-button>
            </el-upload>
            <div
              class="equipmentImgList"
              v-if="!!dialogVisibleEditData.info?.equipmentImgJsonUrl"
            >
              <div
                class="equipmentImgLiBox"
                v-for="(item, i) in dialogVisibleEditData.info
                  ?.equipmentImgJsonUrl"
                :key="item + i"
              >
                <img :src="imageAddress(item)" />
                <div class="equipmentImgLiBtn">
                  <el-button
                    class="btn"
                    size="mini"
                    icon="el-icon-minus"
                    @click="operateEquipmentImgBtn(i)"
                  ></el-button>
                  <el-button
                    class="btn"
                    size="mini"
                    icon="el-icon-plus"
                    @click="operateEquipmentImgBtn(i, item)"
                  ></el-button>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="示例视频">
            <el-upload
              action="https://jsonplaceholder.typicode.com/posts/"
              :http-request="uploadExampleVideoUrlVideo"
              ref="uploadExampleVideoUrlVideo"
              :show-file-list="false"
              :multiple="false"
            >
              <el-button size="mini" type="primary">上传视频</el-button>
            </el-upload>
            <div v-if="!!dialogVisibleEditData.info?.exampleVideoUrl">
              <video controls width="300">
                <source
                  :src="
                    this.$imageAddress(
                      dialogVisibleEditData.info.exampleVideoUrl
                    )
                  "
                  type="video/mp4"
                />
              </video>
            </div>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveEditBtn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import {
  importAIConfig,
  flushedAI,
  getSubjectCount,
  getAIConfigPage,
  getAIConfigItem,
  getAIConfigExam,
  getAIInfo,
  uploadAIInfo,
  getAINotRelevancyInfo,
  cancelRelevancy,
  setRelevancy,
  getRelevancyData,
  deleteAIInfo,
  switchAiOperation,
} from '@/api/scoreAI/experimentManagement.js'
import { fileSingle, fileMultiple } from '@/api/fileUpload/fileUpload.js'
import { debounce } from '@/utils/util'
export default {
  name: 'experimentManagement',
  data() {
    return {
      loading: false,
      //
      checkedNum: 0,
      subjectList: [
        { subjectType: 1, subjectTotal: 0 },
        { subjectType: 2, subjectTotal: 0 },
        { subjectType: 3, subjectTotal: 0 },
      ],
      // 条件
      params: {
        subjectType: '',
        query: '',
      },
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 1,
      },
      // 生物显微镜显示
      microscopeShow: false,
      selectionList: [],
      // 关联弹框
      dialogVisibleRelevancy: false,
      dialogVisibleRelevancyData: [],
      relevancyExamQuestionOperationTestId: '',
      // 左
      itemDTOList: [],
      // 右
      stepsList: [],
      // 编辑弹框
      dialogVisible: false,
      dialogVisibleEditData: {},

      imageAddress: this.$imageAddress,
      // 多个上传数量
      addNum: 0,
    }
  },
  mounted() {
    this.getSubjectCountFunc()
    this.getAIConfigPageFunc()
  },
  methods: {
    // 切换
    clickBtn(i, subjectType) {
      if (this.checkedNum == i + 1) {
        this.checkedNum = 0
        this.params.subjectType = undefined
      } else {
        this.checkedNum = i + 1
        this.params.subjectType = subjectType
      }
      this.tableDataPagination.pageIndex = 1
      this.changeConditionForm()
    },
    // 条件过滤
    changeConditionForm() {
      this.selectionList = []
      let conditionObj = JSON.parse(JSON.stringify(this.params))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getAIConfigPageFunc(this.$qs.stringify(conditionObj))
    },
    // 获取实验管理列表
    getAIConfigPageFunc(data) {
      this.loading = true
      getAIConfigPage(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize,
        data
      )
        .then((res) => {
          this.loading = false
          // console.log('res', res)
          if (res.success) {
            this.tableData = res.data
            this.tableDataPagination.total = res.total
          }
        })
        .catch((err) => {})
    },
    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.tableDataPagination.pageIndex = 1
      this.changeConditionForm()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.changeConditionForm()
    },
    // 展示全部AI考题数量
    getSubjectCountFunc() {
      getSubjectCount()
        .then((res) => {
          if (res.success) {
            let {
              biologicalQuestionCount,
              chemicalQuestionCount,
              physicalQuestionCount,
            } = res.data
            this.subjectList = [
              { subjectType: 1, subjectTotal: physicalQuestionCount },
              { subjectType: 2, subjectTotal: biologicalQuestionCount },
              { subjectType: 3, subjectTotal: chemicalQuestionCount },
            ]
          }
        })
        .catch((err) => {})
    },
    // 导入
    uploadBtn(data) {
      // let fd = new FormData()
      // fd.append('file', data.file)
      // importAIConfig(fd)
      //   .then((res) => {
      //     if (res.success) {
      //       this.$message.success('导入成功')
      //       this.tableDataPagination.pageIndex = 1
      //       this.changeConditionForm()
      //     } else {
      //       this.$message.info(res.msg)
      //     }
      //   })
      //   .catch((err) => {})
    },
    // 导入AI配置
    importConfigBtn(data) {
      let fd = new FormData()
      fd.append('file', data.file)
      importAIConfig(fd)
        .then((res) => {
          if (res.success) {
            this.$message.success('导入成功')
            this.tableDataPagination.pageIndex = 1
            this.changeConditionForm()
          } else {
            this.$message.info(res.msg)
          }
          this.$refs.upload.clearFiles() // 清空历史记录
        })
        .catch((err) => {})
    },
    // 刷新Ai处理器
    flushedAIBtn() {
      this.$confirm('确认重新加载吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          flushedAI()
            .then((res) => {
              if (res.success) {
                this.$message.success('导入成功')
              } else {
                this.$message.info(res.msg)
              }
            })
            .catch((err) => {})
        })
        .catch(() => {
          this.$message.info('已取消')
        })
    },
    // 删除
    async deleteAIInfoFunc(list) {
      await deleteAIInfo(list)
        .then((res) => {
          if (res.success) {
            this.$message.success('删除成功')
            this.tableDataPagination.pageIndex = 1
            this.changeConditionForm()
          }
        })
        .catch((err) => {})
    },
    // 关联
    async relevancyBtn(row, isExam = '') {
      this.relevancyExamQuestionOperationTestId = ''
      this.itemDTOList = []
      this.stepsList = []
      console.log('关联', row)
      await this.getAIInfoFunc(row.aiOperationQuestionId)
      await this.getAINotRelevancyInfoFunc(row.subjectType)
      // 是否已关联
      if (!!isExam) {
        console.log('isExam', isExam)
        // 请求已选中的步骤
        let data = await this.getRelevancyDataFunc(isExam)
        // 添加选中步骤
        this.dialogVisibleRelevancyData.unshift(data)
        this.relevancyExamQuestionOperationTestId = isExam
        this.relevancyChangSelect()
      }
      this.dialogVisibleRelevancy = true
    },
    // 关联切换
    relevancyChangSelect() {
      this.itemDTOList =
        this.dialogVisibleRelevancyData.find(
          (e) =>
            e.examQuestionOperationTestId ===
            this.relevancyExamQuestionOperationTestId
        )?.itemDTOList || []
    },
    // 保存关联
    async saveRelevancyBtn() {
      let data = {
        aiOperationQuestionId: this.dialogVisibleEditData.aiOperationQuestionId,
        examQuestionOperationTestId: this.relevancyExamQuestionOperationTestId,
      }
      if (!!data.examQuestionOperationTestId) {
        await this.setRelevancyFunc(data)
      } else {
        await this.cancelRelevancyFunc(data)
      }
    },
    // 编辑
    async editBtn(row) {
      console.log('编辑', row)
      this.microscopeShow = row.subjectType === 2 ? true : false
      await this.getAIInfoFunc(row.aiOperationQuestionId)
      this.dialogVisible = true
    },
    // 上传编辑头像
    async uploadAvatarUrlImg(e) {
      let url = await this.fileSingleFunc(e)
      console.log(url)
      if (!this.dialogVisibleEditData.info) {
        this.dialogVisibleEditData.info = {}
      }
      this.dialogVisibleEditData.info.avatarUrl = url
      this.$refs.uploadAvatarUrlImg.clearFiles()
      this.$forceUpdate()
    },
    // 上传电子试卷
    async uploadPaperImgUrlImg(e) {
      let url = await this.fileSingleFunc(e)
      console.log(url)
      if (!this.dialogVisibleEditData.info) {
        this.dialogVisibleEditData.info = {}
      }
      this.dialogVisibleEditData.info.paperImgUrl = url
      this.$refs.uploadAvatarUrlImg.clearFiles()
      this.$forceUpdate()
    },
    // 上传实验图标
    async uploadEquipmentImgJsonUrlImg(e) {
      // this.fileMultipleFunc(e, files)
      let url = await this.fileSingleFunc(e)
      console.log(url)
      if (!this.dialogVisibleEditData.info) {
        this.dialogVisibleEditData.info = {}
        this.dialogVisibleEditData.info.equipmentImgJsonUrl = []
      }
      this.dialogVisibleEditData.info.equipmentImgJsonUrl.push(url)
      // this.$refs.uploadEquipmentImgJsonUrlImg.clearFiles()
      this.$forceUpdate()
    },
    // 操作实验图标
    operateEquipmentImgBtn(i, val = null) {
      if (!!val) {
        this.dialogVisibleEditData.info.equipmentImgJsonUrl.splice(
          i + 1,
          0,
          val
        )
      } else {
        this.dialogVisibleEditData.info.equipmentImgJsonUrl.splice(i, 1)
      }
    },
    // 上传实验视频
    async uploadExampleVideoUrlVideo(e) {
      let loadingInstance = Loading.service({
        text: '上传中',
      })
      let url = await this.fileSingleFunc(e)
      console.log(url)
      if (!this.dialogVisibleEditData.info) {
        this.dialogVisibleEditData.info = {}
      }
      this.dialogVisibleEditData.info.exampleVideoUrl = url
      this.$refs.uploadExampleVideoUrlVideo.clearFiles()
      this.$forceUpdate()
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close()
      })
    },
    // 编辑保存
    saveEditBtn() {
      let {
        aiOperationQuestionId,
        aiOperationTestContent,
        info,
        microscope,
        subjectType,
      } = this.dialogVisibleEditData
      let data = {
        aiOperationQuestionId,
        microscope,
        subjectType,
        introduction: !!aiOperationTestContent ? aiOperationTestContent : '',
        // avatarUrl: info.avatarUrl,
        // paperImgUrl: info.avatarUrl,
        // exampleVideoUrl: info.avatarUrl,
        // equipmentImgJsonUrl: info.avatarUrl,
        // aiOperationQuestionInfoId: info.avatarUrl,
      }
      if (!!info) {
        const infoList = [
          'avatarUrl',
          'paperImgUrl',
          'exampleVideoUrl',
          'equipmentImgJsonUrl',
          'aiOperationQuestionInfoId',
        ]
        infoList.forEach((e) => {
          if (info[e]) {
            data[e] = info[e]
          }
        })
      }
      uploadAIInfo(data)
        .then((res) => {
          if (res.success) {
            this.$message.success('保存成功')
            this.changeConditionForm()
            this.dialogVisible = false
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 评分配置
    scoreBtn(row) {
      this.$router.push({
        path: '/scoreAI/scoreDisposition',
        query: {
          aiOperationQuestionId: row.aiOperationQuestionId,
        },
      })
    },
    // 步骤编辑
    editStepsBtn(row) {
      // 跳转
      console.log('编辑', row)
      this.$router.push({
        path: `/scoreAI/editingStepExperimentManagement?
        examInfo=${row.aiOperationQuestionId}`,
      })
    },
    // 电子试卷编辑
    electronPaperEditBtn(data) {
      // 跳转
      console.log('编辑', data)
    },
    // 删除
    deleteBtn(flayList, row) {
      console.log('row', row, this.selectionList)
      let arr = []
      if (flayList) {
        if (!this.selectionList || !this.selectionList.length) {
          return this.$message.info('请先勾选')
        }
        arr = this.selectionList.map((e) => e.aiOperationQuestionId)
      } else {
        arr = [row.aiOperationQuestionId]
      }
      let fd = new FormData()
      fd.append('idList', arr)
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deleteAIInfoFunc(fd)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 展示所有未被关联的考试考试
    async getAINotRelevancyInfoFunc(val) {
      let fd = new FormData()
      fd.append('subjectType', val)
      await getAINotRelevancyInfo(fd)
        .then((res) => {
          console.log('展示所有未被关联的考试考试', res)
          if (res.success) {
            this.dialogVisibleRelevancyData = res.data
          }
        })
        .catch((err) => {})
    },
    // 展示已关联的考试考题详细信息
    async getRelevancyDataFunc(val) {
      try {
        let res = await getRelevancyData(val)
        if (res.success) {
          return res.data
        } else {
          this.$message.warning(res.msg)
        }
      } catch (error) {}
    },
    // 取消关联
    async cancelRelevancyFunc(val) {
      let fd = new FormData()
      fd.append('aiOperationQuestionId', val.aiOperationQuestionId)
      await cancelRelevancy(fd)
        .then((res) => {
          if (res.success) {
            this.$message.success('保存成功')
            this.changeConditionForm()
            this.dialogVisibleRelevancy = false
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 关联Ai考题和考试考题
    async setRelevancyFunc(val) {
      let fd = new FormData()
      fd.append('aiOperationQuestionId', val.aiOperationQuestionId)
      fd.append('examQuestionOperationTestId', val.examQuestionOperationTestId)
      await setRelevancy(fd)
        .then((res) => {
          if (res.success) {
            this.$message.success('保存成功')
            this.changeConditionForm()
            this.dialogVisibleRelevancy = false
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {})
    },
    // 获取AI考题详情 编辑/关联
    async getAIInfoFunc(val) {
      await getAIInfo(val)
        .then((res) => {
          console.log('获取AI考题详情', res)
          if (res.success) {
            this.dialogVisibleEditData = res.data
            this.stepsList = res.data.steps
          }
        })
        .catch((err) => {})
    },
    // 更新AI考题详情 编辑
    async uploadAIInfoFunc(val) {
      await uploadAIInfo(val)
        .then((res) => {
          console.log('更新AI考题详情', res)
          if (res.success) {
          }
        })
        .catch((err) => {})
    },
    // 根据Ai考题id展示所有评分项
    async getAIConfigItemFunc(val) {
      await getAIConfigItem(val)
        .then((res) => {
          console.log('根据Ai考题id展示所有评分项', res)
          if (res.success) {
          }
        })
        .catch((err) => {})
    },
    // 获取AI配置详情,根据考题id
    async getAIConfigExamFunc(val) {
      await getAIConfigExam(val)
        .then((res) => {
          console.log('获取AI配置详情,根据考题id', res)
          if (res.success) {
          }
        })
        .catch((err) => {})
    },
    // 单个上传
    async fileSingleFunc(e) {
      let fd = new FormData()
      fd.append('code', 'default')
      fd.append('file', e.file)
      try {
        let res = await fileSingle(fd)
        if (res.success) {
          return res.data.url
        } else {
          this.$message.warning(res.msg)
        }
      } catch (err) {
        // 处理错误
      }
    },
    // 多个上传
    async fileMultipleFunc(e, files) {
      let fd = new FormData()
      fd.append('code', 'default')
      console.log('e.files', files)
      files.forEach((element) => {
        console.log('element', element)
        fd.append('fileList', element.file)
      })
      //   await fileMultiple(fd)
      //     .then((res) => {
      //       if (res.success) {
      //         let urlList = res.map((e) => e.url)
      //         return urlList
      //       } else {
      //         this.$message.warning(res.msg)
      //         return false
      //       }
      //     })
      //     .catch((err) => {})
    },
    switchAiMarking: debounce(function (row) {
      let fd = new FormData()
      fd.append('aiMarking', row.aiMarking ? 1 : 0)
      fd.append('aiOperationQuestionId', row.aiOperationQuestionId)
      switchAiOperation(fd)
        .then((res) => {
          console.log('res', res)
          if (res.success) {
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    }, 500),
  },
  filters: {
    subjectFilter(key, font = '') {
      switch (key) {
        case 1:
          return `物理${font}`
        case 2:
          return `生物${font}`
        case 3:
          return `化学${font}`
        default:
          return `未知学科${font}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__expanded-cell {
  padding: 0;
}

// 编辑头像
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  width: 178px;
  height: 178px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.uploadImg {
  max-width: 178px;
  max-height: 178px;
}
.uploadImgPaper {
  max-width: 300px;
  max-height: 300px;
}

.equipmentImgList {
  display: flex;
  flex-wrap: wrap;
  .equipmentImgLiBox {
    position: relative;
    width: 72px;
    height: 72px;
    margin: 0 4px;
    margin-bottom: 20px;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &:hover .equipmentImgLiBtn {
      display: flex;
    }
    .equipmentImgLiBtn {
      position: absolute;
      bottom: -20px;
      display: flex;
      display: none;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .el-button {
        width: 45%;
        margin: 0;
        height: 20px;
        line-height: 20px;
        padding: 0;
      }
      // left: calc(50% - 28px);
      // width: 56px;
      // height: 24px;
      // line-height: 24px;
      // padding: 0;
    }
  }
}
</style>
<style lang="scss">
.examQuestionsManageTop {
  border-bottom: 1px solid #c8dbec;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.subjectBox {
  width: 100%;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  border: 2px solid #409EFF00;
  cursor: pointer;
  height: 70px;
  color: rgb(102, 102, 102);
  font-size: 14px;
  padding: 6px;
  padding-left: 66px;
  position: relative;

  .iconBig {
    // background-image: linear-gradient(to bottom, #2885da, #6eb7fc);
    width: 52px;
    height: 52px;
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    // i {
    //   font-size: 30px;
    //   color: white;
    // }
    img {
      width: 100%;
      height: 100%;
    }
  }

  span {
    display: block;
    color: #409eff;
    font-size: 26px;

    i {
      font-style: normal;
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
  }

  &:hover {
    background: rgb(213, 213, 213);
  }

  &.active {
    border: 2px solid #409eff;
  }
}
</style>
